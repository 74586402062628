<template>
    <div v-if="isDataRetrieved" class="bg-white mb-3">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <h1 class="h4 mb-0">Update order - {{patient_name}}</h1>
            </div>
        </div>
    </div>
    <form v-if="isDataRetrieved" enctype="multipart/form-data" novalidate class="bg-white" @submit.prevent="submitOrder">
        <div v-if="this.sections.map(section => section.name).indexOf('scans') >= 0"> 
            <AccordionFormHeading 
                :isValid="!this.invalidSections.includes('scans')"
                :isActive="this.activeSections.includes('scans')"
                @click.prevent="toggleSection('scans')"
                label="Scans"
                :number="this.sections.map(section => section.name).indexOf('scans') + 1"
            />
            <div v-if="this.activeSections.includes('scans')" class="py-3">
                <div class="mb-2">
                    <FileInput
                        label="Scans"
                        v-model="scansSection.scans"
                        :v="v$.scansSection.scans"
                        :allowedExtensions="['ply', 'stl']"
                    />
                </div>
            </div>
        </div>
        <AccordionFormHeading 
            :isValid="!this.invalidSections.includes('account')"
            :isActive="this.activeSections.includes('account')"
            @click="toggleSection('account')"
            label="Account"
            :number="this.sections.map(section => section.name).indexOf('account') + 1"
        />
        <div v-if="this.activeSections.includes('account')" class="py-3">
            <div class="row">
                <div class="col-md-6 mb-2">
                    <DropdownInput
                        label="Account"
                        v-model="accountSection.account_id"
                        :v="v$.accountSection.account_id"
                        :options="accounts"
                    />
                </div>
            </div>
        </div>
        <AccordionFormHeading 
            :isValid="!this.invalidSections.includes('basic')"
            :isActive="this.activeSections.includes('basic')"
            @click="toggleSection('basic')"
            label="Patient &amp; Service level"
            :number="this.sections.map(section => section.name).indexOf('basic') + 1"
        />
        <div v-if="this.activeSections.includes('basic')" class="py-3">
            <div class="row">
                <div v-if="preferences.showPatientName" class="col-sm-4 mb-2">
                    <TextInput
                        label="First name"
                        v-model="basicSection.first_name"
                        :v="v$.basicSection.first_name"
                    />
                </div>
                <div v-if="preferences.showPatientName" class="col-sm-4 mb-2">
                    <TextInput
                        label="Last name"
                        v-model="basicSection.last_name"
                        :v="v$.basicSection.last_name"
                    />
                </div>
                <div class="col-sm-4 mb-2">
                    <TextInput
                        label="Patient reference"
                        v-model="basicSection.patient_reference"
                        :v="v$.basicSection.patient_reference"
                    />
                </div>
                <div class="col-sm-5 col-md-4 mb-2">
                    <DropdownInput
                        label="Service level"
                        v-model="basicSection.standard"
                        :v="v$.basicSection.standard"
                        :options="standards"
                    />
                </div>
            </div>
        </div>
        <AccordionFormHeading 
            :isValid="!this.invalidSections.includes('products')"
            :isActive="this.activeSections.includes('products')"
            @click="toggleSection('products')"
            label="Products"
            :number="this.sections.map(section => section.name).indexOf('products') + 1"
        />
        <div v-if="this.activeSections.includes('products')" class="py-3">
            <div class="mb-2">
                <ProductInput
                    v-model="productsSection.products"
                    :v="v$.productsSection.products"
                    :service_level="basicSection.standard"
                    :displayPopup="false"
                    :accountId="this.accountSection.account_id"
                    :impressionType="this.impressionType"
                />
            </div>
        </div>
        <AccordionFormHeading 
            :isValid="!this.invalidSections.includes('instruction')"
            :isActive="this.activeSections.includes('instruction')"
            @click="toggleSection('instruction')"
            label="Instructions"
            :number="this.sections.map(section => section.name).indexOf('instruction') + 1"
        />

        <div v-if="this.activeSections.includes('instruction') && isAligner">

            <ToothChart v-bind="{ teethChartAnnotations:instructionSection.teethChartAnnotations}" ></ToothChart>

            <div class="row mt-4">
                <div class="col-sm-5 col-md-4 mb-2">
                    <DropdownInput
                        label="Treatment Type"
                        v-model="instructionSection.treatment_type"
                        :v="v$.instructionSection.treatment_type"
                        :options="instructionSection.treatment_types"
                        remove-empty-option="true"
                    />
                </div>
                <div class="col-sm-5 col-md-4 mb-2">
                <DropdownInput
                    label="Arches"
                    v-model="instructionSection.arch"
                    :v="v$.instructionSection.arch"
                    :options="instructionSection.arches"
                    remove-empty-option="true"
                />
              </div>
              <div class="col-sm-6 col-md-4 mb-2">
                <DropdownInput
                    label="Trim Line"
                    v-model="instructionSection.trim_line"
                    :v="v$.instructionSection.trim_line"
                    :options="instructionSection.trim_lines"
                    remove-empty-option="true"
                />
              </div>
                <div class="col-sm-5 col-md-4 mb-2">
                    <DropdownInput
                        label="IPR"
                        v-model="instructionSection.ipr_request"
                        :v="v$.instructionSection.ipr_request"
                        :options="instructionSection.ipr_requested"
                        remove-empty-option="true"
                    />
                </div>
              <div class="col-sm-5 col-md-4 mb-2">
                <DropdownInput
                    label="Attachments"
                    v-model="instructionSection.attachment"
                    :v="v$.instructionSection.attachment"
                    :options="instructionSection.attachments_needed"
                    remove-empty-option="true"
                />
              </div>
            </div>
            <div class="col-md-12 mb-2 mt-2">
                <TextareaInput
                    label="Patient Complaints"
                    v-model="instructionSection.complaint"
                    :v="v$.instructionSection.complaint"
                    rows="3"
                />
            </div>
            <div class="row">
                <div class="col-md-12 mb-2">
                    <TextareaInput
                        label="Other Notes/Instructions"
                        v-model="instructionSection.comment"
                        :v="v$.instructionSection.comment"
                        rows="4"
                    />
                </div>
                
            </div>
        </div>
        <div v-if="this.activeSections.includes('instruction')" class="py-3">
            <div class="row" v-if="isRequiresShade">
                <div class="col-6 col-md-4 mb-2">
                    <TextInput
                        label="Shade"
                        v-model="instructionSection.shade"
                        :v="v$.instructionSection.shade"
                    />
                </div>
            </div>
            <div class="row" v-if="!isAligner">
                <div class="col-md-8 mb-2">
                    <TextareaInput
                        label="Instructions"
                        v-model="instructionSection.note"
                        :v="v$.instructionSection.note"
                        rows="6"
                    />
                </div>
            </div>
            <div class="row" v-if="!isAligner">
                <div class="mb-2">
                    <AnnotationInput
                        label="Notations"
                        v-model="instructionSection.annotations"
                        :v="v$.instructionSection.annotations"
                    />
                </div>
            </div>
            <div class="mb-2">
                <FileInput
                    label="Photos"
                    v-model="instructionSection.photos"
                    :v="v$.instructionSection.photos"
                    :allowedExtensions="['jpg', 'jpeg', 'png', 'heic', 'dcm']"
                    :isAligner="isAligner"
                    :imageUploadGuide="{title: 'The following images should be included in your order',
                    images:[{ src: '/resources/image-upload-guide/aligner/teeth-front.webp', alt: 'Teeth front' },
                { src: '/resources/image-upload-guide/aligner/teeth-lh.webp', alt: 'Teeth left side' },
                { src: '/resources/image-upload-guide/aligner/teeth-rh.webp', alt: 'Teeth right side' },
                { src: '/resources/image-upload-guide/aligner/teeth-upper.webp', alt: 'Teeth Upper' },
                { src: '/resources/image-upload-guide/aligner/teeth-lower.webp', alt: 'Teeth lower' }]}"
                />
            </div>
        </div>
        <AccordionFormHeading 
            :isValid="!this.invalidSections.includes('delivery')"
            :isActive="this.activeSections.includes('delivery')"
            @click="toggleSection('delivery')"
            label="Delivery"
            :number="this.sections.map(section => section.name).indexOf('delivery') + 1"
            v-if="isAligner == false"
        />
        <div v-if="this.activeSections.includes('delivery') && isAligner == false" class="py-3">
            <div class="row">
                <div class="col-sm-12 col-md-12 mb-2">
                    <DeliveryDateInput
                        label="Delivery date"
                        v-model="deliverySection.patient_date"
                        :v="v$.deliverySection.patient_date"
                        :account_id="this.accountSection.account_id"
                        :order_type="this.impressionType"
                        :standard="this.basicSection.standard"
                        :products="this.productsSection.products.map(x => x.id)"
                    />
                </div>
            </div>
        </div>
        <div v-if="this.sections.map(section => section.name).indexOf('voucher') >= 0">
            <AccordionFormHeading 
                :isValid="!this.invalidSections.includes('voucher')"
                :isActive="this.activeSections.includes('voucher')"
                @click="toggleSection('voucher')"
                label="Voucher"
                :number="this.sections.map(section => section.name).indexOf('voucher') + 1"
            />
            <div v-if="this.activeSections.includes('voucher')" class="py-3">
                <h5>Voucher</h5>
                <VoucherInput
                    v-model="voucherSection.voucher"
                    :v="v$.voucherSection.voucher"
                    :vouchers="vouchers"
                />
            </div>
        </div>
      <div v-if="isAligner == true" class="mb-2 mt-2" >
        <p>
          <waiver
              label="waiver"
              v-model="instructionSection.waiver"
              :v="v$.instructionSection.waiver"
          />
        </p>

      </div>
        <div class="buttons">
            <button class="btn btn-primary me-1" type="submit">Submit</button>
            <router-link class="btn btn-light" :to="{ name: 'PendingOrders' }">Cancel</router-link>
        </div>
    </form>
</template>

<style scoped lang="scss">
    .buttons
    {
        padding-top: 20px;
    }

</style>

<script>
    import api from "@/services/api";
    import FileInput from '@/components/FileInput.vue';
    import TextInput from '@/components/TextInput.vue';
    import TextareaInput from '@/components/TextareaInput.vue';
    import DropdownInput from '@/components/DropdownInput.vue';
    import ProductInput from '@/components/ProductInput.vue';
    import VoucherInput from '@/components/VoucherInput.vue';
    import DeliveryDateInput from '@/components/DeliveryDateInput.vue';
    import AnnotationInput from '@/components/AnnotationInput.vue';
    import AccordionFormHeading from '@/components/AccordionFormHeading.vue';
    import ToothChart from '@/components/ToothChart.vue';
    import useVuelidate from '@vuelidate/core'
    import {required, requiredIf, numeric, minLength, maxLength, helpers} from '@vuelidate/validators'
    import Waiver from "@/components/waiver.vue";

    const todayDate = () => {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return new Date(today.setDate(today.getDate()+1)); 
    };

    const rangeEndDate = () => {
        var today = new Date();
        today.setHours(0, 0, 0, 0);

        return new Date(today.setMonth(today.getMonth()+1)); 
    };
    
    const isNotWeekend = (value) => { 
        var day = new Date(value).getUTCDay();

        if([6,0].includes(day)) {
            return false;
        }

        return true;
    };

    const isAfterToday = (value) => {
        var date = new Date(value);
        date.setHours(0, 0, 0, 0);
        return date.getTime() >= todayDate().getTime();
    };

    const isBeforeRangeEnd = (value) => {
        var date = new Date(value);
        date.setHours(0, 0, 0, 0);
        return date.getTime() <= rangeEndDate().getTime()
    };

    const validateReference = (regexParam) => {
        return helpers.withParams({regex: regexParam}, (value) => { 
            return regexParam === null || value === null || value.length === 0 || value.match(new RegExp(regexParam)) !== null
        });
    };

    const IMPRESSION_TYPE_DIGITAL = 1,
        IMPRESSION_TYPE_PHYSICAL = 2;

    export default {
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        props: {
            id: {
                required: true
            }
        },
        data() {
            return {
                vuelidateExternalResults: {
                    deliverySection: {
                        patient_date: [],
                    }
                },
                isDataRetrieved: false,
                patient_name: null,
                accounts: [],
                standards: [],
                vouchers: [],
                impressionType: null,
                is_aligner: false,
                scansSection: {
                    scans: [],
                },
                accountSection: {
                    account_id: null,
                },
                basicSection: {
                    first_name: '',
                    last_name: '',
                    patient_reference: '',
                    standard: '',
                },
                productsSection: {
                    products: [],
                },
                instructionSection: {
                    shade: '',
                    note: '',
                    annotations: [],
                    photos: [],

                    //alligner
                    treatment_types: [],
                    treatment_type: null,
                    arches: [],
                    arch: null,
                    ipr_requested: [],
                    ipr_request: null,
                    attachments_needed: [],
                    attachment: null,
                    comment:'',
                    complaint: '',
                    teethChartAnnotations: [],
                    trim_lines: [],
                    trim_line: '',
                    waiver: false,
                }, 
                deliverySection: {
                    patient_date: '',
                },
                voucherSection: {
                    voucher: null,
                },
                preferences: {
                    showPatientName: true,
                    patientRefRegex: null,
                },
                activeSections: [],
                invalidSections: [],
            }
        },
        computed: {
            sections() {
                var sections = [
                    {
                        name: 'scans',
                        label: 'Scans',
                        validation: 'scansSection',
                    },
                    {
                        name: 'account',
                        label: 'Account',
                        validation: 'accountSection',
                    },
                    {
                        name: 'basic',
                        label: 'Patient',
                        validation: 'basicSection',
                    },
                    {
                        name: 'products',
                        label: 'Products',
                        validation: 'productsSection',
                    },
                    {
                        name: 'instruction',
                        label: 'Instructions',
                        validation: 'instructionSection',
                    },
                    {
                        name: 'delivery',
                        label: 'Delivery',
                        validation: 'deliverySection',
                    },
                    {
                        name: 'voucher',
                        label: 'Voucher',
                        validation: 'voucherSection',
                    },
                ];

                if(this.impressionType == IMPRESSION_TYPE_PHYSICAL) // Physical
                {
                    sections = sections.filter(section => !['scans'].includes(section.name))
                }
                
                if(!this.vouchers.length) // No vouchers
                {
                    sections = sections.filter(section => !['voucher'].includes(section.name))
                }

                return sections;
            },
            isRequiresShade() {
                return this.productsSection.products.filter(product => product.requires_shade).length > 0;
            },
            isAligner() {
                return this.productsSection.products.filter(product => product.is_aligner).length > 0;
            }
        },
        validations () {
            return {
                scansSection: {
                    scans: { required },
                },
                accountSection: {
                    account_id: { required },
                },
                basicSection: {
                    first_name: { requiredIf: requiredIf(!this.basicSection.last_name && !this.basicSection.patient_reference) },
                    last_name: { requiredIf: requiredIf(!this.basicSection.first_name && !this.basicSection.patient_reference) },
                    patient_reference: {
                        requiredIf: requiredIf(!this.basicSection.first_name && !this.basicSection.last_name),
                        mustBeValidReference: helpers.withMessage('Reference is Invalid', validateReference(this.preferences.patientRefRegex)),
                    },
                    standard: { required },
                },
                productsSection: {
                    products: { required },
                },
                instructionSection: {
                    shade: { minLength: minLength(1), maxLength: maxLength(15), requiredIf: requiredIf(this.isRequiresShade) },
                    note: { maxLength: maxLength(1000) },
                    annotations: { maxLength: maxLength(100), },
                    photos: {  },

                    //alligner
                    treatment_type: { requiredIf: requiredIf(this.isAligner) },
                    ipr_request: { requiredIf: requiredIf(this.isAligner) },
                    attachment: { requiredIf: requiredIf(this.isAligner) },
                    arch: { requiredIf: requiredIf(this.isAligner) },
                    trim_line: { requiredIf: requiredIf(this.isAligner) },
                    comment: { maxLength: maxLength(1000) },
                    complaint: { maxLength: maxLength(1000) },
                    waiver: {requiredIf: requiredIf(function() {
                        return this.isAligner;
                    }),
                    isTrue: function() {
                        return !this.isAligner || this.instructionSection.waiver === true;
                    }, 
                 },
                },
                deliverySection: {
                    patient_date: {
                        requiredIf: this.isAligner == false, 
                        isNotWeekend: this.isAligner == false && helpers.withMessage('Date cannot be a weekend', isNotWeekend),
                        isAfterToday: this.isAligner == false && helpers.withMessage('Date cannot be in the past', isAfterToday),
                        isBeforeRangeEnd: this.isAligner == false && helpers.withMessage('Date must be in the next two months', isBeforeRangeEnd),
                    },
                },
                voucherSection: {
                    voucher: { numeric },
                },
            }
        },
        components: {
          Waiver,
            TextInput,
            DropdownInput,
            FileInput,
            ProductInput,
            VoucherInput,
            TextareaInput,
            DeliveryDateInput,
            AnnotationInput,
            AccordionFormHeading,
            ToothChart, 
        },
        mounted () {
            this.fetchPracticePrefs()
            .then(() => {
                var orderPromise = this.fetchOrderData();
                var accountsPromise = this.fetchAccounts();
                var standardsPromise = this.fetchStandards();

                Promise.all([orderPromise, accountsPromise, standardsPromise]).then(() => {
                    this.setInvalidSections(true);
                    this.setDraftStatus();
                    this.isDataRetrieved = true;
                });
            });
            this.fetchAlignerOptions();
        },
        methods: {
            fullName: function (account) {
                return account.dentist.first_name + ' ' + account.dentist.surname + ' (' + account.code + ')';
            },
            fetchOrderData: function() {  
                return new Promise((resolve, reject) => {
                    api
                    .get('/api/v1/temp-orders/' + this.id)
                    .then((response) => {
                        var orderData = response.data.data;
                        this.impressionType = orderData.has_barcode ? IMPRESSION_TYPE_PHYSICAL : IMPRESSION_TYPE_DIGITAL;

                        // Scans
                        this.scansSection.scans = orderData.scans.map((scan) => {
                            return {
                                name: scan.name,
                                hash: scan.hash,
                                processed: true,
                                file: null,
                                uploadData: null,
                            };
                        });

                        // Account
                        this.accountSection.account_id = orderData.account_id;

                        // Basics
                        this.patient_name = orderData.patient_name;
                        if(this.preferences && this.preferences.showPatientName)
                        {
                            this.basicSection.first_name = orderData.first_name;
                            this.basicSection.last_name = orderData.surname;
                        }
                        this.basicSection.patient_reference = orderData.patient_reference;
                        this.basicSection.standard = orderData.standard ? orderData.standard.id : null;

                        // Products
                        this.productsSection.products = orderData.products.map((product) => {
                            return {
                                id: product.product_id,
                                name: product.product_name,
                                quantity: product.product_quantity,
                                material_id: product.material_id,
                                requires_shade: product.requires_shade,
                                is_aligner: product.is_aligner,
                            };
                        });
                        
                        // Instructions
                        this.instructionSection.annotations = orderData.annotations.map((annotation) => {
                            return {
                                image: annotation.url,
                                hash: annotation.hash,
                                text: annotation.note,
                            }
                        });

                        this.instructionSection.photos = orderData.files.map((photo) => {
                            return {
                                name: photo.name,
                                hash: photo.hash,
                                processed: true,
                                file: null,
                                uploadData: null,
                            };
                        });
                        
                        this.instructionSection.shade = orderData.shade;
                        this.instructionSection.note = orderData.note;

                        if (this.isAligner && null !== orderData.temp_order_aligner_detail) {
                            // Aligner
                            this.instructionSection.treatment_type = orderData.temp_order_aligner_detail.treatment_type;
                            this.instructionSection.ipr_request = orderData.temp_order_aligner_detail.ipr_requested;
                            this.instructionSection.attachment = orderData.temp_order_aligner_detail.attachments_needed;
                            this.instructionSection.arch = orderData.temp_order_aligner_detail.arches;
                            this.instructionSection.comment = orderData.temp_order_aligner_detail.comment;
                            this.instructionSection.complaint = orderData.temp_order_aligner_detail.complaint;
                            this.instructionSection.teethChartAnnotations = orderData.teeth_chart;
                            this.instructionSection.trim_line = orderData.temp_order_aligner_detail.trim_line;
                        }
                        // Delivery
                        this.deliverySection.patient_date = orderData.patient_date;

                        // Voucher
                        this.voucherSection.voucher = orderData.voucher;

                        resolve();
                    })
                    .catch(() => {
                        console.log('Catch order error');
                        reject();
                    });
                });              
            },
            fetchAccounts: function() {  
                return new Promise((resolve, reject) => {              
                    api
                    .get('/api/v1/accounts', {
                        params: {
                            practice_id: this.$store.state.currentPracticeId,
                        }
                    })
                    .then((response) => {
                        this.accounts = response.data.data.map((account) => { return {
                            id: account.id, 
                            label: this.fullName(account),
                        }});
                        resolve();
                    })
                    .catch(() => {
                        console.log('Catch accounts error');
                        reject();
                    });
                });
            },
            fetchStandards: function() {  
                return new Promise((resolve) => {               
                    this.standards = [{
                        id: 1,
                        label: 'NHS / Standard',
                    }, {
                        id: 2,
                        label: 'Independent',
                    }, {
                        id: 3,
                        label: 'Private',
                    }]
                    resolve();
                });
            },
            fetchAlignerOptions: function() {                
                this.instructionSection.treatment_types = [{
                    id: 3,
                    label: 'As Recommended',
                  }, {
                    id: 0,
                    label: '3-3 Social Smile',
                }, {
                    id: 1,
                    label: '5-5 Pre molar to pre molar',
                }, {
                    id: 2,
                    label: '7-7 Full treatment',
                }];
                this.instructionSection.ipr_requested = [{
                    id: 2,
                    label: 'As Recommended',
                }, {
                    id: 0,
                    label: 'Yes',
                }, {
                    id: 1,
                    label: 'No',
                }];
                this.instructionSection.attachments_needed = [{
                    id: 2,
                    label: 'As Recommended',
                }, {
                    id: 0,
                    label: 'Yes',
                }, {
                    id: 1,
                    label: 'No',
                }];
                this.instructionSection.arches = [{
                    id: 0,
                    label: 'Both',
                }, {
                    id: 1,
                    label: 'Upper',
                }, {
                    id: 2,
                    label: 'Lower',
                }];

                this.instructionSection.trim_lines = [{
                  id: 0,
                  label: 'As Recommended',
                }, {
                  id: 1,
                  label: 'Full Scalloped',
                }, {
                  id: 2,
                  label: 'Straight (+1mm)',
                }, {
                  id: 3,
                  label: 'Straight (+2mm)',
                }];
            },
            fetchVouchers: function() {      
                this.vouchers = [];
                if(this.accountSection.account_id && this.productsSection.products && this.productsSection.products.length)
                {
                    api
                    .get('/api/v1/vouchers', {
                        params: {
                            account_id: this.accountSection.account_id,
                            products: this.productsSection.products.map(x => x.id),
                        }
                    })
                    .then((response) => {
                        this.vouchers = response.data;
                    })
                    .catch(() => {
                        console.log('Catch voucher error');
                    });   
                }     
            },
            setInvalidSections: function(resetDirty) {
                this.validateAllSections()
                .then((values) => {
                    this.invalidSections = values.map((isValue, index) => {
                        return !isValue ? this.sections[index].name : null;
                    }).filter(x => x);

                    this.activeSections = [...this.invalidSections];
                })
                .then(() => {
                    if(resetDirty)
                    {
                        this.v$.$reset();
                    }
                });
            },
            validateAllSections: function() {
                return new Promise((resolve, reject) => {
                    var promises = this.sections.map((section) => {
                        return this.v$[section.validation].$validate();
                    });

                    Promise.all(promises)
                    .then((values) => {
                        console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                        resolve(values)
                    })
                    .catch(() => {
                        reject();
                    })
                });
            },
            submitOrder: async function () {
                this.v$.$clearExternalResults();

                this.validateAllSections()
                .then((values) => {
                    if(values.every(value => value === true))
                    {
                        this.requestOrderUpdate();
                    }
                    else
                    {
                        this.setInvalidSections(false);
                    }
                });
            },
            orderRequestData: function() {
                var data = {
                    order_type: this.impressionType,
                    account_id: this.accountSection.account_id,
                    first_name: this.basicSection.first_name,
                    last_name: this.basicSection.last_name,
                    patient_reference: this.basicSection.patient_reference,
                    standard: this.basicSection.standard,
                    patient_date: this.deliverySection.patient_date,
                    note: this.instructionSection.note,
                    aligner_detail: {
                        treatment_type: this.instructionSection.treatment_type,
                        ipr_requested: this.instructionSection.ipr_request,
                        attachments_needed: this.instructionSection.attachment,
                        arches: this.instructionSection.arch,
                        comment: this.instructionSection.comment,
                        ipr_complaint: this.instructionSection.complaint,
                        trim_line: this.instructionSection.trim_line,
                        complaint: this.instructionSection.complaint,
                    },
                    teeth_chart_annotations: this.instructionSection.teethChartAnnotations,
                    products: this.productsSection.products.map((product) => {
                        return {
                            id: product.id,
                            quantity: product.quantity,
                            material_id: product.material_id,
                        }
                    }),
                };

                // Supply scans data if digital impressions selected. 
                if(this.impressionType == IMPRESSION_TYPE_DIGITAL)
                {
                    data.scans = this.scansSection.scans.map(scan => scan.hash);
                }

                if(this.instructionSection.shade)
                {
                    data.shade = this.instructionSection.shade;
                }

                if(this.instructionSection.photos)
                {
                    data.files = this.instructionSection.photos.map(photo => photo.hash);
                }

                if(this.instructionSection.annotations)
                {
                    data.annotations = this.instructionSection.annotations.map((annotation) => {
                        var annotationReturn = {
                            file_id: annotation.hash,
                        };

                        if(annotation.text && annotation.text.length)
                        {
                            annotationReturn.text = annotation.text;
                        }

                        return annotationReturn;
                    });
                }

                if(this.voucherSection.voucher)
                {
                    data.voucher_id = this.voucherSection.voucher;
                }

                data.is_aligner = this.isAligner;

                return data;
            },
            setDraftStatus: function () {
                api.post('/api/v1/temp-orders/' + this.id + '/draft')
                .catch(() => {
                    console.log('Catch set draft status error');
                });
            },
            requestOrderUpdate: function () {
                api.put('/api/v1/temp-orders/' + this.id, this.orderRequestData())
                .then(() => {
                    this.$router.push({ name: 'PendingOrders' });
                })
                .catch((error) => {
                    console.log('Catch request order update error');

                    // Set the errors from the server
                    if(error.response && error.response.data && error.response.data.errors)
                    {
                        // Set the errors from the server
                        const errors = { deliverySection: {
                            patient_date: error.response.data.errors.patient_date,
                        }};

                        Object.assign(this.vuelidateExternalResults, errors);

                        // Revalidate to trigger new errors
                        this.validateAllSections()
                        .then(() => {
                            // Then set the invalid sections again
                            this.setInvalidSections(false);
                        });
                    }
                });
            },
            fetchPracticePrefs: function () {
                return new Promise((resolve, reject) => { 
                    api
                    .get('/api/v1/practices/' + this.$store.state.currentPracticeId + '/preferences', {
                    })
                    .then((response) => {
                        this.preferences = {
                            showPatientName: response.data.data.providing_patient_names,
                            patientRefRegex: response.data.data.patient_ref_regex,
                        };
                        resolve();
                    })
                    .catch((reason) => {
                        console.log('Catch practice preferences error');
                        console.log(reason);
                        reject();
                    });
                });
            },
            toggleSection: function(sectionName) {
                if(this.activeSections.includes(sectionName))
                {
                    var index = this.activeSections.indexOf(sectionName);
                    this.activeSections.splice(index, 1);
                }
                else
                {                    
                    this.activeSections.push(sectionName);
                }
            }
        },
        watch: {
            account_id() {
                this.fetchVouchers();
            },
            'productsSection.products': {
                handler() {
                    this.fetchVouchers();
                },
                deep: true    
            },
        },
    }

</script>
