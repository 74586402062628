<template>
    <div v-if="isDataRetrieved" class="bg-white mb-3">
        <div class="d-flex justify-content-between align-items-center">
            <div>
                <h1 class="h4 mb-0">Update Aligner Details - {{patient_name}} / #{{instructionSection.order_id}}</h1>
            </div>
        </div>
    </div>
    <form v-if="isDataRetrieved" enctype="multipart/form-data" novalidate class="bg-white" @submit.prevent="submitOrder">

        <div>
          <ToothChart v-bind="{ teethChartAnnotations:instructionSection.teethChartAnnotations}" ></ToothChart>
            <div class="row mt-4">
                <div class="col-sm-5 col-md-4 mb-2">
                    <DropdownInput
                        label="Treatment Type"
                        v-model="instructionSection.treatment_type"
                        :v="v$.instructionSection.treatment_type"
                        :options="instructionSection.treatment_types"
                        remove-empty-option="true"
                    />
                </div>
                <div class="col-sm-5 col-md-4 mb-2">
                  <DropdownInput
                      label="Arches"
                      v-model="instructionSection.arch"
                      :v="v$.instructionSection.arch"
                      :options="instructionSection.arches"
                      remove-empty-option="true"
                  />
                </div>
                <div class="col-sm-6 col-md-4 mb-2">
                  <DropdownInput
                      label="Trim Line"
                      v-model="instructionSection.trim_line"
                      :v="v$.instructionSection.trim_line"
                      :options="instructionSection.trim_lines"
                      remove-empty-option="true"
                  />
                </div>
                <div class="col-sm-5 col-md-4 mb-2">
                    <DropdownInput
                        label="IPR"
                        v-model="instructionSection.ipr_request"
                        :v="v$.instructionSection.ipr_request"
                        :options="instructionSection.ipr_requested"
                        remove-empty-option="true"
                    />
                </div>
                <div class="col-sm-5 col-md-4 mb-2">
                  <DropdownInput
                      label="Attachments Needed"
                      v-model="instructionSection.attachment"
                      :v="v$.instructionSection.attachment"
                      :options="instructionSection.attachments_needed"
                      remove-empty-option="true"
                  />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mb-2 mt-2">
                  <TextareaInput
                      label="Patient Complaints"
                      v-model="instructionSection.complaint"
                      :v="v$.instructionSection.complaint"
                      rows="4"
                  />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 mb-2">
                  <TextareaInput
                      label="Other Notes/Instructions"
                      v-model="instructionSection.comment"
                      :v="v$.instructionSection.comment"
                      rows="4"
                  />
                </div>
            </div>
        </div>

            <div class="mb-2">
                <FileInput
                    label="Photos"
                    v-model="instructionSection.photos"
                    :v="v$.instructionSection.photos"
                    :isAligner="true"
                    :allowedExtensions="['jpg', 'jpeg', 'png', 'heic', 'dcm']"
                    :imageUploadGuide="{title: 'The following images should be included in your order',
                    images:[{ src: '/resources/image-upload-guide/aligner/teeth-front.webp', alt: 'Teeth front' },
                { src: '/resources/image-upload-guide/aligner/teeth-lh.webp', alt: 'Teeth left side' },
                { src: '/resources/image-upload-guide/aligner/teeth-rh.webp', alt: 'Teeth right side' },
                { src: '/resources/image-upload-guide/aligner/teeth-upper.webp', alt: 'Teeth upper' },
                { src: '/resources/image-upload-guide/aligner/teeth-lower.webp', alt: 'Teeth lower' }]}"
                />
            </div>

      <div class="mb-2 mt-2" >
        <p>
          <waiver
              label="waiver"
              v-model="instructionSection.waiver"
              :v="v$.instructionSection.waiver"
          />
        </p>

      </div>

        <div class="buttons">
            <button class="btn btn-primary me-1" type="submit" :disabled="isSubmitting">Submit</button>
            <router-link class="btn btn-light" :to="{ name: 'Order', params: { id:instructionSection.order_id }}">Cancel</router-link>
        </div>
    </form>
</template>

<style scoped lang="scss">
    .buttons
    {
        padding-top: 20px;
    }

</style>

<script>
    import api from "@/services/api";
    import FileInput from '@/components/FileInput.vue';
    import TextareaInput from '@/components/TextareaInput.vue';
    import DropdownInput from '@/components/DropdownInput.vue';
    import useVuelidate from '@vuelidate/core'
    import {required, maxLength, sameAs} from '@vuelidate/validators'
    import ToothChart from "@/components/ToothChart.vue";
    import Waiver from "@/components/waiver.vue";
    import router from "@/router";

    export default {
        setup () {
            return {
                v$: useVuelidate()
            }
        },
        props: {
            id: {
                required: true
            }
        },
        data() {
            return {
                isDataRetrieved: false,
                instructionSection: {
                    order_id: null,
                    photos: [],

                    //alligner
                    treatment_types: [],
                    treatment_type: 3,
                    arches: [],
                    arch: 0,
                    ipr_requested: [],
                    ipr_request: 2,
                    attachments_needed: [],
                    attachment: 2,
                    comment:'',
                    complaint: '',
                    teethChartAnnotations: [],
                    trim_lines: [],
                    trim_line: 0,
                },
                activeSections: [],
                invalidSections: [],
                isSubmitting: false
            }
        },
        computed: {
            sections() {
                var sections = [
                    {
                        name: 'instruction',
                        label: 'Instructions',
                        validation: 'instructionSection',
                    },
                ];

                return sections;
            },
        },
        validations () {
            return {
                instructionSection: {
                    photos: {  },

                    //alligner
                    treatment_type: { required: required},
                    ipr_request: { required: required },
                    attachment: { required: required},
                    arch: { required: required },
                    trim_line: { required: required},
                    comment: { maxLength: maxLength(1000) },
                    complaint: { maxLength: maxLength(1000) },
                    waiver: {required: required, checked: sameAs(true) },
                },
            }
        },
        components: {
          Waiver,
          ToothChart,
            DropdownInput,
            FileInput,
            TextareaInput,

        },
        mounted () {
          var orderPromise = this.fetchOrderData();
          Promise.all([orderPromise]).then(() => {
            this.setInvalidSections(true);
            this.isDataRetrieved = true;
          });


            this.fetchAlignerOptions();
        },
        methods: {
            fetchOrderData: function() {  
                return new Promise((resolve, reject) => {
                    api
                    .get('/api/v1/aligners/' + this.id + '/details')
                    .then((response) => {
                        var orderData = response.data.data;

                        // Basics
                        this.patient_name = orderData.patient_name;

                        // Products

                       if(orderData.aligner_detail  === null)
                       {
                         alert("This order doesn't have an aligner.");
                         return;
                       }


                      if (null !== orderData.aligner_detail) {
                        // Aligner
                        if(this.hasValue(orderData.treatment_type)){
                          router.push({ name: 'Order', params: { id: orderData.order_id }})
                          return;
                        }

                        this.instructionSection.treatment_type = this.hasValue(orderData.treatment_type) ? orderData.treatment_type : 3;
                        this.instructionSection.ipr_request = this.hasValue(orderData.ipr_requested) ? orderData.ipr_requested : 2;
                        this.instructionSection.attachment = this.hasValue(orderData.attachments_needed) ? orderData.attachments_needed : 2;
                        this.instructionSection.arch = this.hasValue(orderData.arches) ? orderData.arches : 0;
                        this.instructionSection.comment = orderData.comment;
                        this.instructionSection.trim_line = this.hasValue(orderData.trim_line) ? orderData.trim_line : 0;
                        this.instructionSection.order_id = orderData.order_id
                      }
                        resolve();
                    })
                    .catch(() => {
                        console.log('Catch order error');
                        reject();
                    });
                });              
            },
            hasValue: function (object){
              if(typeof object === undefined){
                  return false;
              }

              if(object === null){
                  return false;
              }

              return true;
            },
            fetchAlignerOptions: function() {                
                this.instructionSection.treatment_types = [{
                    id: 3,
                    label: 'As Recommended',
                }, {
                    id: 0,
                    label: '3-3 Social Smile',
                }, {
                    id: 1,
                    label: '5-5 Pre molar to pre molar',
                }, {
                    id: 2,
                    label: '7-7 Full treatment',
                }];
                this.instructionSection.ipr_requested = [{
                    id: 2,
                    label: 'As Recommended',
                }, {
                    id: 0,
                    label: 'Yes',
                }, {
                    id: 1,
                    label: 'No',
                }];
                this.instructionSection.attachments_needed = [{
                    id: 2,
                    label: 'As Recommended',
                }, {
                    id: 0,
                    label: 'Yes',
                }, {
                    id: 1,
                    label: 'No',
                }];
                this.instructionSection.arches = [{
                    id: 0,
                    label: 'Both',
                }, {
                    id: 1,
                    label: 'Upper',
                }, {
                    id: 2,
                    label: 'Lower',
                }];

                this.instructionSection.trim_lines = [{
                  id: 0,
                  label: 'As Recommended',
                }, {
                  id: 1,
                  label: 'Full Scalloped',
                }, {
                  id: 2,
                  label: 'Straight (+1mm)',
                }, {
                  id: 3,
                  label: 'Straight (+2mm)',
                }];
            },
            setInvalidSections: function(resetDirty) {
                this.validateAllSections()
                .then((values) => {
                    this.invalidSections = values.map((isValue, index) => {
                        return !isValue ? this.sections[index].name : null;
                    }).filter(x => x);

                    this.activeSections = [...this.invalidSections];
                })
                .then(() => {
                    if(resetDirty)
                    {
                        this.v$.$reset();
                    }
                });
            },
            validateAllSections: function() {
                return new Promise((resolve, reject) => {
                    var promises = this.sections.map((section) => {

                        return this.v$[section.validation].$validate();
                    });

                    Promise.all(promises)
                    .then((values) => {
                        console.log('Validation errors', this.v$.$errors.map((error) => { return error.$property + ': ' + error.$message}));
                        resolve(values)
                    })
                    .catch(() => {
                        reject();
                    })
                });
            },
            submitOrder: async function () {

                if (this.isSubmitting) {
                    return; // If already submitting, exit the function
                }

                this.isSubmitting = true;

                this.validateAllSections()
                .then((values) => {
                    if(values.every(value => value === true))
                    {
                        this.requestOrderUpdate();
                    }
                    else
                    {
                        this.setInvalidSections(false);
                        this.isSubmitting = false;
                    }
                });
            },
            orderRequestData: function() {
                var data = {
                  treatment_type: this.instructionSection.treatment_type,
                  ipr_requested: this.instructionSection.ipr_request,
                  attachments_needed: this.instructionSection.attachment,
                  arches: this.instructionSection.arch,
                  comment: this.instructionSection.comment,
                  complaint: this.instructionSection.complaint,
                  trim_line: this.instructionSection.trim_line,
                  teeth_chart: this.instructionSection.teethChartAnnotations,
                };

              if(this.instructionSection.photos)
              {
                data.files = this.instructionSection.photos.map(photo => photo.hash);
              }

                return data;
            },

            requestOrderUpdate: function () {
                api.put('/api/v1/aligners/' + this.id + '/update-details', this.orderRequestData())
                .then(() => {
                    this.$router.push({ name: 'Orders' });
                })
                .catch((error) => {
                    console.log('Catch request order update error');

                    // Set the errors from the server
                    if(error.response && error.response.data && error.response.data.errors)
                    {

                        // Revalidate to trigger new errors
                        this.validateAllSections()
                        .then(() => {
                            // Then set the invalid sections again
                            this.setInvalidSections(false);
                        });
                    }
                });
            },
        },
    }

</script>
