<template>
    <div class="section" :class="{ 'section-active': this.isActive }" @mousedown.prevent="">
        <div class="info-side">
            <span class="progress-circle">{{this.number}}</span>
            <div class="section-label">
                {{this.label}}
            </div>
        </div>
        <div class="controls-side">
            <div v-if="!this.isValid" :class="{ 'text-danger': !this.isValid }">
                Requires attention
            </div>
            <i class="bi ps-4" :class="{ 'bi-caret-left-fill': !this.isActive, 'bi-caret-down-fill': this.isActive }"></i>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            isActive: {
                default: false,
                required: true,
            },
            isValid: {
                default: true,
                required: true,
            },
            label: {
                required: true,
            },
            number: {
                required: true,
            },
        },
    }
</script>

<style scoped lang="scss">
    .section
    {
        display: flex;
        padding: 20px 0px;
        border-bottom: 1px solid $swift-lighter-grey;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    .info-side
    {
        display: flex;
    }

    .controls-side
    {
        display: flex;
    }

    .progress-circle
    {
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 100px;
        border: 2px solid $swift-fresh-green;
        color: $swift-fresh-green;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        margin-right: 10px;
    }

    .section-active .progress-circle
    {
        background-color: $swift-fresh-green;
        color: #FFFFFF;
    }

    .section-label
    {
        display: flex;
        align-items: center;
    }
</style>