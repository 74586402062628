<template>
    <label>Tooth Annotation</label>
    <p class="small mb-0">Click on a tooth to add a note</p>
    <div class="section-container justtify-content-between row">
    <div class="col-md-12" id="upperRow">
        <div class="toothSlot">
            <img src="/resources/teeth/default/ur8.svg" id="ur8" @click="selectTooth" />
            <br/>
            UR8
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ur7.svg" id="ur7" @click="selectTooth" />
            <br />
            UR7
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ur6.svg" id="ur6" @click="selectTooth" />
            <br />
            UR6
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ur5.svg" id="ur5" @click="selectTooth" />
            <br />
            UR5
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ur4.svg" id="ur4" @click="selectTooth" />
            <br />
            UR4
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ur3.svg" id="ur3" @click="selectTooth" />
            <br />
            UR3
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ur2.svg" id="ur2" @click="selectTooth" />
            <br />
            UR2
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ur1.svg" id="ur1" @click="selectTooth" />
            <br />
            UR1
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ul1.svg" id="ul1" @click="selectTooth" />
            <br />
            UL1
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ul2.svg" id="ul2" @click="selectTooth" />
            <br />
            UL2
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ul3.svg" id="ul3" @click="selectTooth" />
            <br />
            UL3
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ul4.svg" id="ul4" @click="selectTooth" />
            <br />
            UL4
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ul5.svg" id="ul5" @click="selectTooth" />
            <br />
            UL5
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ul6.svg" id="ul6" @click="selectTooth" />
            <br />
            UL6
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ul7.svg" id="ul7" @click="selectTooth" />
            <br />
            UL7
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ul8.svg" id="ul8" @click="selectTooth"/>
            <br />
            UL8
        </div>

        <div class="toothSlot">
            <img src="/resources/teeth/default/lr8.svg" id="lr8" @click="selectTooth" />
            <br/>
            LR8
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/lr7.svg" id="lr7" @click="selectTooth" />
            <br />
            LR7
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/lr6.svg" id="lr6" @click="selectTooth" />
            <br />
            LR6
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/lr5.svg" id="lr5" @click="selectTooth" />
            <br />
            LR5
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/lr4.svg" id="lr4" @click="selectTooth" />
            <br />
            LR4
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/lr3.svg" id="lr3" @click="selectTooth" />
            <br />
            LR3
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/lr2.svg" id="lr2" @click="selectTooth" />
            <br />
            LR2
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/lr1.svg" id="lr1" @click="selectTooth" />
            <br />
            LR1
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ll1.svg" id="ll1" @click="selectTooth" />
            <br />
            LL1
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ll2.svg" id="ll2" @click="selectTooth" />
            <br />
            LL2
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ll3.svg" id="ll3" @click="selectTooth" />
            <br />
            LL3
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ll4.svg" id="ll4" @click="selectTooth" />
            <br />
            LL4
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ll5.svg" id="ll5" @click="selectTooth" />
            <br />
            LL5
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ll6.svg" id="ll6" @click="selectTooth" />
            <br />
            LL6
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ll7.svg" id="ll7" @click="selectTooth" />
            <br />
            LL7
        </div>
        <div class="toothSlot">
            <img src="/resources/teeth/default/ll8.svg" id="ll8" @click="selectTooth"/>
            <br />
            LL8
        </div>
    </div>
    </div>
    <br />

    
    <div class="modal fade" :id="'tooth-modal'"  tabindex="-1" data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="row p-4">
                    <div class="col-sm-2 mt-sm-5 pt-2 text-center">
                        <img :src="'/resources/teeth/default/' + this.selectedTooth + '.svg'" />
                        <h5 class="text-center text-muted text-uppercase mt-2">{{ selectedTooth }}</h5><br />
                    </div>
                <div class="col-sm-10">
                   
                    <input type="hidden" name="teeth[]">
                    <label class="form-label">Tooth State: </label>
                        <select class="form-control mb-1" :class="{ 'is-invalid': v$.selectedToothState.$error }"  v-model="selectedToothState">
                            <option selected="true" value="">Select one...</option>
                            <option v-for="state in teethStates" :value="state.value" :key="state.value">
                                {{ state.label }}
                            </option>
                        </select>
                        <div class="invalid-feedback" v-if="v$.selectedToothState.$error">This field is required</div>
                    <label class="form-label mt-2">Comment</label>
                    <textarea rows="3" class="form-control" v-model="selectedToothNote"></textarea>
                </div>
            </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary flex-grow-1 me-auto" @click="onModalSave">Save changes</button>
                    <button type="button" class="btn btn-secondary " @click="this.selectedToothState = ''; this.selectedToothNote = ''">Clear</button>
                    <button type="button" class="btn btn-danger text-white" data-bs-dismiss="modal" @click="onModalClose">Close</button>
                </div>
            </div>
        </div>
    </div>
  
    <br />
</template>

<style scoped lang="scss">
#upperRow {
    margin-top: 1rem;
}
.toothSlot {
    display: inline-block;
    transition: .3s all;
    text-align: center;
    width: 6.25%;

    img { width: 100%; }

    @media screen and (max-width: 768px){
        width:12.5%;
    }
    @media screen and (max-width: 585px){
        width:16.5%;
    }
    @media screen and (max-width: 359px){
        width:25%;
    }
        
}

.toothSlot:hover {
    transform: scale(1.2);
    cursor: pointer;
}
</style>

<script>
import { Modal } from 'bootstrap';
import api from "@/services/api";
import { requiredIf } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
    export default {
        data() {
            return {
                teethStates: [],
                modal: null,
                selectedToothId: null,
                selectedTooth: '',
                selectedToothNote: '',
                selectedToothState: null,
                teethChartAnnotationsCopy: this.teethChartAnnotations,
            };
        },
        props: {
            teethChartAnnotations: {
                type: Array,
                required: true,
            },
        },
        methods: {
            selectTooth: function (tooth) {
                this.selectedTooth = tooth.target.id;
                tooth.target.src = '/resources/teeth/selected/' + this.selectedTooth + '.svg';
                let existingAnnotation = this.teethChartAnnotationsCopy.find(obj => obj.tooth_number === this.selectedTooth);

                this.selectedToothState = existingAnnotation ? existingAnnotation.tooth_state : '';
                this.selectedToothNote = existingAnnotation ? existingAnnotation.comment : '';
                this.selectedToothId = existingAnnotation ? existingAnnotation.id : null;
                
                this.modal = new Modal(document.getElementById('tooth-modal'), {
                    backdrop: 'static',
                    keyboard: false
                });
                this.modal.show();
            },
            fetchTeethStates: function() {
                api
                .get('/api/v1/aligners/fetchTeethStates', {}).then((response) => {
                    this.teethStates = response.data;
                })        
            },
            selectTeeth: function (){
                let elements = document.querySelectorAll('.toothSlot img');
                elements.forEach((imgElement) => {
                let matchingObject = this.teethChartAnnotationsCopy.find(obj => obj.tooth_number === imgElement.id);
                if (matchingObject) {
                    imgElement.src = '/resources/teeth/selected/' + imgElement.id + '.svg';
                }
                });
            },

            onModalClose: function () {
                const index = this.teethChartAnnotationsCopy.findIndex(annotation => annotation.tooth_number === this.selectedTooth);
                if(index === -1) {
                    document.getElementById(this.selectedTooth).src = '/resources/teeth/default/' + this.selectedTooth + '.svg';
                }
                if(!this.selectedToothState && !this.selectedToothNote){  
                    document.getElementById(this.selectedTooth).src = '/resources/teeth/default/' + this.selectedTooth + '.svg';
                    if (index !== -1) {
                        this.teethChartAnnotationsCopy.splice(index, 1);
                    }
                }
                this.v$.$reset();
            },
            async onModalSave() {
                this.v$.$touch();
                if (this.v$.$pending) {
                    await this.v$.$validate();
                }
                if (this.v$.$invalid) {
                    return;
                }
                this.modal.hide();
                const index = this.teethChartAnnotationsCopy.findIndex(annotation => annotation.tooth_number === this.selectedTooth);
                if(!this.selectedToothState && !this.selectedToothNote){
                   document.getElementById(this.selectedTooth).src = '/resources/teeth/default/' + this.selectedTooth + '.svg';
                   if (index !== -1) {
                        this.teethChartAnnotationsCopy.splice(index, 1);
                    }
                this.$emit('this.teethChartAnnotationsCopy', this.teethChartAnnotations);

               } else {
                const newAnnotation = {id:this.selectedToothId, tooth_number:this.selectedTooth, tooth_state: this.selectedToothState, comment: this.selectedToothNote};
                if (index !== -1) {
                    this.teethChartAnnotationsCopy.splice(index, 1, newAnnotation);
                } else {
                    this.teethChartAnnotationsCopy.push(newAnnotation);
                }
               }
               this.$emit('this.teethChartAnnotationsCopy', this.teethChartAnnotations);
               this.v$.$reset();
               
            }
        },
        validations() {
            return {
                selectedToothState: { 
                    requiredIf: requiredIf(function() {
                    return this.selectedToothNote;
                    })
                }
            }
        },
        setup() {
            return { v$: useVuelidate() }
        },
        mounted () {
           this.fetchTeethStates();
           this.selectTeeth();
            
        }
    }
</script>