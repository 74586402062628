<template>
    <p v-if="voucher"><strong>Voucher: </strong> {{voucher.effect_name}}</p>
    <div>
        <button type="button" class="btn btn-secondary" :class="{ 'is-invalid': v && v.$error }" v-on:click="addVoucher">
            Add Voucher ({{vouchers.length}} available)
        </button>
        <span class="invalid-feedback" v-if="v && v.$error">{{v.$errors[0].$message}}</span>
    </div>
    <div class="modal fade" id="voucher-modal" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add voucher</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-secondary" v-for="voucher of vouchers" v-bind:key="voucher.id" v-on:click.prevent="selectVoucher(voucher)">{{voucher.effect_name}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    
</style>

<script>
    import { Modal } from 'bootstrap';

    export default {
        props: {
            modelValue: {
                required: true,
            },
            v: {
                required: true,
            },
            vouchers: {
                required: true,
            },
        },
        data() {
            return {
                voucher: null,
                modal: null,
            }
        },
        emits: ['update:modelValue'],
        methods: {
            addVoucher: function()
            {
                this.modal = new Modal(document.getElementById('voucher-modal'));
                this.modal.show();
            },
            selectVoucher: function(voucher)
            {
                this.modal.hide();
                this.voucher = voucher;
                this.$emit('update:modelValue', this.voucher.ids[0]);
            },
        },
    }
</script>
