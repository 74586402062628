<template>
  <p :class="{'error': v && v.$error}">
    <input
        :value="modelValue"
        :class="{ 'is-invalid': v && v.$error }"
        type="checkbox"
        @input="$emit('update:modelValue', $event.target.checked)"
    />

    I hereby confirm that I have filled out the form completely and truthfully.
  </p>
</template>
 <style scoped lang="scss">
 .error {
   width: 100%;
   margin-top: 0.25rem;
   font-size: 0.875em;
   color: #ed5854;
 }
 </style>
<script>

export default {
  props: {
    label: {
      required: true
    },
    v: {
      required: true,
    },
    modelValue: {
      required: true,
    },
    rows: {
      default: 3,
    },
  },
  emits: ['update:modelValue'],
}
</script>
<script setup>
</script>